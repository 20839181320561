import Moment from 'moment'

const getMenuMapper = function (menus, keyInMenu) {
  let result = {}
  menus.forEach((menu) => {
    if (menu[keyInMenu]) {
      result[menu[keyInMenu]] = menu
    }
  })
  return result
}

const getThumbnailUrl = function (url) {
  // const prefix = 'https://buildit-blog-homepage.s3.ap-northeast-2.amazonaws.com/'
  const prefix = 'https://blog.buildit.kr/'
  if (!url) return ''
  return prefix + url
}

const getHeaderImagePathFromThumbnailUrl = function (path) {
  let str = ''
  if (path) str = path
  let result = str.replace('_thumb', '')
  return result
}

const Post = function (item) {
  this.id = item.id
  this.title = item.title
  this.content = item.contentNl
  this.menu = item.menu
  this.date = item.date
  this.category = item.category
  this.isRecommended = item.isRecommended
  this.isRecentPost = item.isRecentPost
  this.thumbnailPath = getThumbnailUrl(item.thumbnailPath)
  this.desc = item.desc
}

const getAllPosts = function (data) {
  let postEdges = data.blog.allPosts.edges
  let parsed = []
  parsed = postEdges.map(function (edge) {
      let node = edge.node
      return {
        id: node.id,
        title: node.title,
        content: node.contentNl,
        menu: node.menu,
        date: node.date,
        category: node.category,
        isRecommended: node.isRecommended,
        isRecentPost: node.isRecentPost,
        thumbnailPath: getThumbnailUrl(node.thumbnailPath),
        desc: node.desc
      }
    })
  return parsed
}

const getPostsInMenu = function (data, path) {
  let postList = [], results = []
  if (data) postList = getAllPosts(data)
  postList.forEach((post) => {
    let menu = post.menu
    if (menu.urlLink === path) {
      results.push(post)
    }
  })
  return results
}

const getAllPostsToSearch = function (data) {
  let allPosts = data.blog.allPosts
  let postEdges = [], parsed = []
  if (allPosts) postEdges = allPosts.edges
  parsed = postEdges.map(function (edge) {
      let node = edge.node
      return {
          id: node.id,
          title: node.title,
          content: node.contentNl,
          menu: node.menu,
          menuName: node.menu.name.toLowerCase(),
          category: node.category,
          categoryName: node.category.name.toLowerCase(),
          thumbnailPath: getThumbnailUrl(node.thumbnailPath),
          desc: node.desc,
          date: node.date
      }
  })
  return parsed
}

const getParsedPaginationData = function (rawData, lm) {
  let limit = 9
  if (rawData.length === 0) return []
  if (lm) limit = lm

  rawData.sort((a, b) => {
    return b.date - a.date
  })

  let data = JSON.parse(JSON.stringify(rawData))
  let max = data.length
  let cnt = Math.floor(max / limit) + (Math.floor(max % limit) > 0 ? 1 : 0)
  let results = []

  for (let i = 0; i < cnt; i++) {
    results.push(data.splice(0, limit))
  }
  // let results = JSON.parse(JSON.stringify(rawData))
  return results
}

const MenuConfig = {
  '/team': { title: '팀', subTitle: 'TEAM' },
  '/tech': { title: '기술', subTitle: 'Technology' },
  '/tutorials': { title: '튜토리얼', subTitle: 'TUTORIAL' },
  '/cases': { title: '사용/구축 사례', subTitle: 'CASES' },
  '/news': { title: '뉴스', subTitle: 'NEWS' }
}

const getCategoriesByMenuUri = function (categories, uri, addTotal) {
  let results = []
  let list = []
  if (categories) list = categories
  
  list.forEach((category) => {
    let menu = category.menu, urlLink = menu.urlLink
    if (urlLink === uri) {
      results.push({
        id: category.id,
        name: category.name,
        isActive: false
      }) 
    }
  })
  if (addTotal) {
    results.unshift({
      id: null,
      name: '전체',
      isActive: true
    })
  }
  return results
}

const getShuffledArray = function (array) {
  let j, x, i
  for (i = array.length; i; i -= 1) {
    j = Math.floor(Math.random() * i)
    x = array[i - 1]
    array[i - 1] = array[j]
    array[j] = x
  }
  return array
}

const getCurrentPageFromLocationProp = function (props) {
  let result = 1
  let loc = props.location
  if (loc && loc.state && typeof loc.state.currentPage !== 'undefined') {
    result = loc.state.currentPage
  }
  return result
}

const getValueFromLocationProps = function (props, key, initialValue) {
  let result = null
  let loc = props.location

  if (typeof initialValue !== 'undefined') {
    result = initialValue
  }

  if (loc && loc.state && typeof loc.state[key] !== 'undefined') {
    result = loc.state[key]
  }
  return result
}

const getQueryParameterFromUrl = function (name, url) {
  url = decodeURI(url)
  name = name.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]");
  let regexS = "[\\?&]"+name+"=([^&#]*)";
  let regex = new RegExp( regexS );
  let results = regex.exec( url );
  return results == null ? null : results[1];
}

const getHashParameterFromUrl = function (name, location) {
  let param = location.hash.substr(1)
  let keyword = decodeURI(param)
  keyword = keyword.replace(name+'=', '')
  return keyword
}

const getJsonLDFormatted = function (list) {
  let data = []
  if (list) data = list
  let result = {
    "@context": "https://schema.org",
    "@type": "ItemList",
    "itemListElement": []
  }

  result.itemListElement = data.map((dataItem, index) => {
    const companyName = '(주)빌드잇'
    const defaultDate = Moment(dataItem.date).format('YYYY-MM-DD')
    return {
      '@type': 'ListItem',
      'position': String(index + 1),
      'url': 'https://blog.buildit.kr/post/' + dataItem.id,
      'image': dataItem.thumbnailPath,
      'name': dataItem.title,
      'description': dataItem.desc
    }
    // return {
    //   '@type': 'ListItem',
    //   'position': String(index + 1),
    //   'item':{
    //     '@type': 'BlogPosting',
    //     'name': dataItem.title,
    //     'image': dataItem.thumbnailPath,
    //     'url': 'https://blog.buildit.kr/post/' + dataItem.id,
    //     // 'url': 'https://blog.buildit.kr/',
    //     'mainEntityOfPage': {
    //       '@type': 'WebPage',
    //       '@id': 'https://blog.buildit.kr/post/' + dataItem.id,
    //     },
    //     'headline': dataItem.title,
    //     'author': {
    //       '@type': 'Organization',
    //       'name': companyName
    //     },
    //     'publisher': {
    //       '@type': 'Organization',
    //       'name': companyName,
    //       'logo': {
    //         '@type': 'ImageObject',
    //         'url': 'http://buildit.kr/static/images/logo.png',
    //         'width': 123,
    //         'height': 40
    //       }
    //     },
    //     'datePublished': defaultDate,
    //     'dateModified': defaultDate
    //   }
    // }
  })
  return result
}

const getPostJsonLDFormatted = function (postItem) {
  let result = {}
  if (postItem) {
    result = {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://blog.buildit.kr/post/" + postItem.id
      },
      "headline": postItem.title,
      "description": postItem.desc,
      "image": postItem.thumbnailPath,  
      "author": {
        "@type": "Organization",
        "name": "(주)빌드잇"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "(주)빌드잇",
        "logo": {
          "@type": "ImageObject",
          "url": "http://buildit.kr/static/images/logo.png",
          "width": 123,
          "height": 40
        }
      },
      "datePublished": postItem.date,
      "dateModified": postItem.date
    }
  }
  return result
}

export default {
  Post,
  getMenuMapper,
  getAllPosts,
  getAllPostsToSearch,
  getParsedPaginationData,
  getThumbnailUrl,
  MenuConfig,
  getCategoriesByMenuUri,
  getHeaderImagePathFromThumbnailUrl,
  getPostsInMenu,
  getShuffledArray,
  getCurrentPageFromLocationProp,
  getValueFromLocationProps,
  getQueryParameterFromUrl,
  getHashParameterFromUrl,
  getJsonLDFormatted,
  getPostJsonLDFormatted
}